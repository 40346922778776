import { ReactComponent as EventsImage } from '@assets/images/undraw_events.svg';
import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Button, DialogContent, Stack, Typography, useTheme } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import dayjs from 'dayjs';
import { useCalendarAvailabilitiesQuery, useGetLoginInfoQuery } from 'gql/index';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

export const OutlookCalendarConsentAlert = () => {
  const { formatMessage } = useIntl();
  const { me } = useGetMe();
  const { projectId } = useCurrentProject();
  const calendarScheduleStartTime = useRef<string>(dayjs().toISOString());
  const calendarScheduleEndTime = useRef<string>(dayjs().add(1, 'hour').toISOString());
  const [isConsentDialogOpen, setIsConsentDialogOpen] = useState(false);
  const { data: loginInfo } = useGetLoginInfoQuery();
  const theme = useTheme();
  const { data: availabilities } = useCalendarAvailabilitiesQuery({
    input: {
      startTime: calendarScheduleStartTime.current,
      endTime: calendarScheduleEndTime.current,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeIntervalInMinutes: 5,
      memberId: me?.id ?? '',
      projectId: projectId
    }
  }, { enabled: me?.id != null });

  if (loginInfo?.loginInfo.isMicrosoftAccountLogin != true || availabilities?.calendarAvailabilities.msalUIRequired != true) {
    return undefined;
  }

  return <Alert severity='info'>
    <AlertTitle>{formatMessage({ id: 'Outlook calendar link' })}</AlertTitle>
    <Stack direction={'row'} alignItems={'center'} width={'100%'} spacing={4} justifyContent={'space-between'}>
      <Typography>{formatMessage({ id: 'Linking your Outlook calendar will let your clients book meetings easily according to your availabilites.' })} </Typography>
      <Button onClick={() => setIsConsentDialogOpen(true)}>{formatMessage({ id: 'Link Outlook calendar' })}</Button>
    </Stack>
    <ResponsiveDialog open={isConsentDialogOpen} onClose={() => setIsConsentDialogOpen(false)}>
      <DialogContent>
        <Stack alignItems={'center'} justifyContent={'center'} gap={4} p={2}>
          <Typography variant='h4' textAlign={'center'}>{formatMessage({ id: 'Additional permissions needed' })}</Typography>
          <Typography variant='body1' textAlign={'center'}>{formatMessage({ id: 'Additional permissions are need to allow users to book meetings with you based on your Outlook calendar.' })}</Typography>
          <EventsImage fill={theme.palette.primary.main} height={'200px'} width={'auto'} />
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMore />} ><Typography variant='h6' fontWeight={600}>{formatMessage({ id: 'Read/write calendar information' })}</Typography> </AccordionSummary>
            <AccordionDetails>
              {formatMessage({ id: 'View your availabilities and create calendar events.' })}

            </AccordionDetails>
          </Accordion>

          <Typography>{formatMessage({ id: 'Permissions can be revoked at all times by accessing your {appsPortal}' }, {
            appsPortal: <a href="https://myapps.microsoft.com/">Microsoft 365 apps</a>
          })}
          </Typography>
          <Button variant='contained' onClick={() => { window.location.href = `${location.origin}/${getTenantIdentifier()}/api/Graph/ConsentCalendarsPermission?returnUrl=${location.href}`; }}>
            {formatMessage({ id: 'Grant Access' })}
          </Button>

        </Stack>
      </DialogContent>
    </ResponsiveDialog>
  </Alert>;
};