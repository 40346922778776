import { Container, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { RequestTable } from './RequestTable';


export const PendingRequestTab: React.FC = () => {
  const { formatMessage } = useIntl();
  return (<Stack overflow='auto'>
    <Container sx={{ py: 2, display: 'flex', gap: 2, flexDirection: 'column' }}>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='h5'>
          {formatMessage({ id: 'Meetings' })}
        </Typography>
      </Stack>

      <RequestTable />
    </Container >
  </Stack >
  );
};