import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

export const useDueDateFormatter = () => {
  const { formatMessage } = useIntl();


  const formatDueDate = (dueDate: string) => {
    const daysDiffWithDueDate = dayjs(dueDate).startOf('day').diff(dayjs().startOf('day'), 'days');

    return daysDiffWithDueDate === 0 ? formatMessage({ id: 'Due today' })
      : dayjs(dueDate).isAfter(dayjs())
        ? formatMessage({ id: 'Due in {daysDiffWithDueDate}' }, { daysDiffWithDueDate: dayjs.duration({ days: daysDiffWithDueDate }).humanize() })
        : formatMessage({ id: 'Due {daysDiffWithDueDate}' }, { daysDiffWithDueDate: dayjs.duration({ days: daysDiffWithDueDate }).humanize(true) });
  };

  return formatDueDate;
};