import { LoadingButton } from '@mui/lab';
import { AccordionActions, Typography } from '@mui/material';
import { WorkflowActionForExecutionFragmentFragment, WorkflowActionStatus } from 'gql/index';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';


interface OwnProps {
  action: WorkflowActionForExecutionFragmentFragment;
  submitAction: WorkflowActionSubmitAction | undefined;
}

export type WorkflowActionSubmitAction = {
  buttonIcon: ReactNode;
  buttonText: string;
  loading?: boolean;
  onClick: () => void;
};

export const ActionAccordionActions: React.FC<OwnProps> = ({ action, submitAction }) => {
  const { formatMessage, formatDate } = useIntl();
  const isActionComplete = action.status === WorkflowActionStatus.Completed;

  const canSubmitAction = action.status === WorkflowActionStatus.InProgress && action.canSubmit;

  return (
    <AccordionActions
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        justifyContent: isActionComplete ? 'flex-start' : 'flex-end'
      }}
    >
      {isActionComplete &&
        <Typography variant='body2'>
          {formatMessage({ id: 'Completed by {participant} - ' }, { participant: action.submittedBy?.fullName }) + formatDate(action.submittedOn, { dateStyle: 'long' })}
        </Typography>
      }

      {canSubmitAction && submitAction && (
        <LoadingButton
          loading={submitAction.loading}
          disabled={submitAction.loading}
          variant='contained'
          startIcon={submitAction.buttonIcon}
          onClick={submitAction.onClick}
        >
          {submitAction.buttonText}
        </LoadingButton>
      )}

    </AccordionActions>
  );
};