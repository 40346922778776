import { FormDrawer } from '@components/Drawers/FormDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { SecurityPrincipalShim } from '@modules/users/components/types';
import { DrawerProps, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import dayjs from 'dayjs';
import { BookingMethod, MeetingTypesQuery, PrincipalType, useAddMeetingTypeMutation, useEditMeetingTypeMutation, useMeetingTypesQuery } from 'gql/index';
import { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';


interface FormValues {
  name: string;
  description: string;
  durationMinutes: string;
  bookingMethod: BookingMethod;
  principals: SecurityPrincipalShim[];
}

const defaultValues: FormValues = {
  name: '',
  description: '',
  durationMinutes: '15',
  bookingMethod: BookingMethod.DirectBooking,
  principals: []
};

type Props = DrawerProps & {
  meetingType?: MeetingTypesQuery['meetingTypes'][number];
  onMeetingTypeCreated?: (meetingTypeId: number) => void;
};

const maxMinutes = 60 * 7;

export const MeetingTypeDrawer = ({ meetingType, onMeetingTypeCreated, ...drawerProps }: Props) => {
  const { notEmpty, validateNumber, validateIsPositive } = useValidationRules();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();

  const { control, handleSubmit, reset } = useForm<FormValues>({ defaultValues });
  const bookingMethod = useWatch({ control: control, name: 'bookingMethod' });

  useEffect(() => {
    if (meetingType) {
      reset({
        name: meetingType.name,
        description: meetingType.description ?? '',
        durationMinutes: dayjs.duration(meetingType.duration).asMinutes().toString(),
        bookingMethod: meetingType.bookingMethod,
        principals: meetingType.meetingRequestsOwners.map(p => ({ id: p.id, principalType: PrincipalType.User }))
          .concat(meetingType.meetingRequestOwnerGroups.map(p => ({ id: p.id, principalType: PrincipalType.SecurityGroup })))
      });
    } else {
      reset(defaultValues);
    }
  }, [meetingType, reset, drawerProps.open]);

  const { mutate: addMeetingType } = useAddMeetingTypeMutation({
    onSuccess: (response) => {
      response.addMeetingType.meetingType?.id && onMeetingTypeCreated?.(response.addMeetingType.meetingType?.id);
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Created meeting type successfully' }));
      invalidateQuery(useMeetingTypesQuery);
    }
  });

  const { mutate: editMeetingType } = useEditMeetingTypeMutation({
    onSuccess: () => {
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Edited meeting type successfully' }));
      invalidateQuery(useMeetingTypesQuery);
    }
  });

  const onSubmit = (values: FormValues) => {
    if (meetingType?.id) {
      editMeetingType({
        input: {
          id: meetingType.id,
          projectId,
          name: values.name,
          description: values.description,
          durationMinutes: Number(values.durationMinutes),
          bookingMethod: values.bookingMethod,
          principals: values.principals.map(a => ({ id: a.id, principalType: a.principalType }))
        }
      });
    } else {
      addMeetingType({
        input: {
          name: values.name,
          projectId,
          description: values.description,
          durationMinutes: Number(values.durationMinutes),
          bookingMethod: values.bookingMethod,
          principals: values.principals.map(a => ({ id: a.id, principalType: a.principalType }))
        }
      });
    }
  };

  return (
    <FormDrawer
      {...drawerProps}
      header={meetingType ? formatMessage({ id: 'Edit meeting type' }) : formatMessage({ id: 'Add meeting type' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
    >
      <Stack p={2} gap={2}>
        <Controller
          control={control}
          name='name'
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              required
              label={formatMessage({ id: 'Name' })}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='description'
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Description' })}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='bookingMethod'
          render={({ field, fieldState: { error } }) => (
            <FormControl sx={{ mb: 1 }}>
              <InputLabel id='label-booking-method'>{formatMessage({ id: 'Booking method' })}</InputLabel>
              <Select
                {...field}
                labelId='label-booking-method'
                error={!!error}
                label={formatMessage({ id: 'Booking method' })}
              >
                <MenuItem key={'directBooking'} value={BookingMethod.DirectBooking}>{formatMessage({ id: 'Direct booking' })}</MenuItem>
                <MenuItem key={'proposeTimes'} value={BookingMethod.ProposeTimes}>{formatMessage({ id: 'Propose times' })}</MenuItem>
              </Select>
              {bookingMethod == BookingMethod.DirectBooking
                && <FormHelperText>{formatMessage({ id: 'A calendar meeting request is sent as soon as a user selects a time slot.' })}</FormHelperText>}
              {bookingMethod == BookingMethod.ProposeTimes
                && <FormHelperText>{formatMessage({ id: 'The user proposes one or more time slots. The calendar meeting request is sent when a time slot is accepted.' })}</FormHelperText>}
            </FormControl>
          )}
        />


        <Controller
          control={control}
          name='durationMinutes'
          rules={{
            validate: {
              notEmpty,
              validateNumber,
              validateIsPositive,
              maxMinutes: value => Number(value) > maxMinutes ? formatMessage({ id: 'Meetings cannot be that long' }) : undefined
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Duration (minutes)' })}
              {...field}
              required
              type='number'
              inputProps={{
                step: 5
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='principals'
          rules={{
            validate: { notEmpty }
          }}
          render={({ field, fieldState: { error } }) => (
            <PrincipalPicker
              multiple
              includeMe
              disableExternalUsers
              required
              label={formatMessage({ id: 'Assign meeting requests to' })}
              value={field.value}
              onChange={users => field.onChange(users)}
              error={error?.message}
            />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};