import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, CircularProgress, LinearProgress, Stack, Tooltip, useTheme } from '@mui/material';
import { DataGridPro, DataGridProProps, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import dayjs from 'dayjs';
import Gantt from 'frappe-gantt';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ToggleButtonGroupAlternate } from '../../../../components/Buttons/ToggleButtonGroupAlternate';
import { GanttOptions, GanttWrapper } from '../../../../components/Tasks/GanttWrapper';
import { ProjectTaskFragmentFragment, ProjectTaskStatus, useProjectTasksQuery, useRescheduleTaskMutation } from '../../../../gql';
import { getEnumValues } from '../../../../utils/enumUtils';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { useCurrentProject } from '../../../projects/utils/useCurrentProject';
import { tasksGanttViewModeState } from '../../atoms';
import { fixHierarchyPaths } from '../../hierarchyHelpers';
import { useTaskMessages } from '../../taskUtils';
import { TaskActions } from '../Actions/TaskActions';
import { TaskFormDrawer } from '../TaskFormDrawer';
import { TaskStatusDisplay } from '../TaskStatusDisplay';
import { TaskTreeDataGroupingCell } from '../TaskTreeDataGroupingCell';
import classes from './TasksGanttView.module.css';

interface Props {
  tasks: ProjectTaskFragmentFragment[];
  loading: boolean;
  readonly?: boolean;
}

const taskHierarchyPathParts = (task: ProjectTaskFragmentFragment) => task.hierarchyPath?.split('/').filter(Boolean).map(Number) ?? [];

const taskClassNameByType: Record<ProjectTaskStatus, string> = ({
  Draft: classes.taskDraft,
  Ready: classes.taskReady,
  Done: classes.taskDone,
  InProgress: classes.taskInProgress
});

const commonColumnOptions: Partial<GridColDef> = {
  disableColumnMenu: true,
  disableReorder: true,
  sortable: false,
};

const options: GanttOptions = {
  header_height: 50,
  column_width: 30,
  step: 24,
  view_modes: ['Day', 'Week', 'Month'],
  bar_height: 20,
  bar_corner_radius: 3,
  arrow_curve: 5,
  padding: 18,
  date_format: 'YYYY-MM-DD',
  language: dayjs.locale().split('-')[0],

  custom_popup_html: ''
};

const lastMonth = dayjs().subtract(1, 'month').startOf('month');

export const TasksGanttView: React.FC<Props> = ({ tasks: _tasks, loading, readonly }) => {
  const apiRef = useGridApiRef();
  const [hiddenRows, setHiddenRows] = useState<ProjectTaskFragmentFragment[]>();
  const [sortedTasks, setSortedTasks] = useState<ProjectTaskFragmentFragment[]>([]);

  const [hideOldTasks, setHideOldTasks] = useState(true);

  const tasks = useMemo(() => hideOldTasks ? _tasks.filter(p => !(p.status === ProjectTaskStatus.Done && dayjs(p.dueDate).isBefore(lastMonth))) : _tasks, [_tasks, hideOldTasks]);

  const hierarchyFixedTasks = useMemo(() => fixHierarchyPaths(tasks, t => t.hierarchyPath), [tasks]);

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.subscribeEvent('rowExpansionChange', (e) => {
        const task = tasks.find(p => p.id === e.id);
        const allChildren = tasks.filter(p => p.id !== task?.id && p.hierarchyPath?.startsWith(task?.hierarchyPath ?? ''));

        if (e.childrenExpanded) {
          setHiddenRows((prevValues) => prevValues?.filter(p => !allChildren.some(q => q.id === p.id)));
        } else {
          setHiddenRows((prevValues) => [...prevValues ?? [], ...allChildren]);
        }
      });

      apiRef.current.subscribeEvent('rowsSet', () => {
        setSortedTasks(apiRef.current.getSortedRows() as ProjectTaskFragmentFragment[]);
      });
    }
  }, [apiRef, tasks]);

  useEffect(() => {
    if (apiRef.current) {
      setSortedTasks(apiRef.current.getSortedRows() as ProjectTaskFragmentFragment[]);
    }
  }, [apiRef]);


  const tasksDataGridRef = useRef<HTMLDivElement>(null);
  const tasksScrollableRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const invalidateQuery = useQueryInvalidator();
  const { palette: { mode } } = useTheme();
  const { projectId } = useCurrentProject();
  const [viewMode, setViewMode] = useRecoilState(tasksGanttViewModeState);

  const [ganttViewScrollYOffset, setGanttViewScrollYOffset] = useState(0);
  const [ganttViewScrollXOffset, setGanttViewScrollXOffset] = useState(0);
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();
  const { formatMessage } = useIntl();
  const { getTaskName } = useTaskMessages();

  const shouldShowTask = useCallback((task: ProjectTaskFragmentFragment) => {
    const isHidden = hiddenRows?.some(p => p.id === task.id);
    if (isHidden) return false;

    const hierarchyPath = task.hierarchyPath;

    const parents = sortedTasks?.filter(p => p.hierarchyPath && hierarchyPath?.startsWith(p.hierarchyPath));

    const isParentHidden = parents?.some(p => hiddenRows?.some(q => q.id === p.id));

    if (isParentHidden) return false;

    return true;
  }, [hiddenRows, sortedTasks]);


  const getTaskDirectParent = useCallback((task: ProjectTaskFragmentFragment) => {
    if (taskHierarchyPathParts(task).length === 1) return;

    return sortedTasks.find(parent => task.hierarchyPath?.startsWith(parent.hierarchyPath ?? '') && taskHierarchyPathParts(task).length - 1 === taskHierarchyPathParts(parent).length);
  }, [sortedTasks]);

  const ganttTasks: Gantt.Task[] = useMemo(() => sortedTasks?.filter(shouldShowTask)
    .map<Gantt.Task>(p => {
      const parent = getTaskDirectParent(p);

      return {
        id: p.id.toString(),
        start: dayjs(p.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
        end: dayjs(p.dueDate).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
        name: getTaskName(p),
        progress: 0,
        custom_class: `${taskClassNameByType[p.status]} ${!parent ? classes.taskGroup : classes.taskLeaf}`,
        dependencies: p.predecessors.map(p => p.id.toString()).join(','),
      };
    }) ?? [], [getTaskDirectParent, getTaskName, shouldShowTask, sortedTasks]);

  const centerTasksScroll = useCallback(() => {
    const tasksDiv = tasksScrollableRef.current;
    const tasksGridCell = tasksDataGridRef.current;
    if (tasksDiv && tasksGridCell && tasksDiv.scrollWidth > 0) {
      tasksDiv.scrollLeft = (tasksDiv.scrollWidth - tasksDiv.clientWidth) / 2 + tasksGridCell.clientWidth;
    }
  }, []);

  useEffect(() => {
    const tasksDiv = tasksScrollableRef.current;
    if (tasksDiv) {
      tasksDiv.scrollLeft = ganttViewScrollXOffset;
      tasksDiv.scrollTop = ganttViewScrollYOffset;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  // Has to be memoized due to MUI bug: https://github.com/mui/mui-x/issues/7771#issuecomment-1920224215
  const getTreeDataPath = useCallback((row: ProjectTaskFragmentFragment) => row.hierarchyPath?.split('/').filter(Boolean) ?? [], []);

  const { mutate: rescheduleTask, isLoading: isRescheduling } = useRescheduleTaskMutation({
    onSuccess: (_, { input: { taskId } }) => {
      const task = sortedTasks.find(p => p.id === taskId);
      const hasChildren = sortedTasks.some(child => child.hierarchyPath?.startsWith(task?.hierarchyPath ?? '') && taskId !== child.id);

      if (hasChildren) {
        invalidateQuery(useProjectTasksQuery, { projectId });
      }
    }
  });

  const disabled = loading || readonly || isRescheduling;

  const onTaskDateChanged = useCallback((ganttTask: Gantt.Task, start: Date, end: Date) => {
    if (dayjs(ganttTask.start).isSame(start, 'day') && dayjs(ganttTask.end).isSame(end, 'day')) return;
    rescheduleTask({
      input: {
        projectId,
        taskId: Number(ganttTask.id),
        startDate: dayjs(start).startOf('day').toISOString(),
        dueDate: dayjs(end).startOf('day').toISOString()
      }
    });
  }, [projectId, rescheduleTask]);

  const onGanttScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.currentTarget;
    setGanttViewScrollYOffset(target.scrollTop);
    setGanttViewScrollXOffset(target.scrollLeft);
  };

  const onTaskDoubleClick = useCallback((task: Gantt.Task) => {
    if (readonly) {
      navigate(`/${getTenantIdentifier()}/projects/${projectId}/tasks/${task.id}`);
    }
    else {
      setSelectedTaskId(Number(task.id));
    }
  }, [navigate, projectId, readonly]);

  if (loading && !tasks) {
    return <Stack mt={10} width={'100%'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress size={80} />
    </Stack>;
  }

  const columns: GridColDef<ProjectTaskFragmentFragment>[] = [{
    ...commonColumnOptions,
    type: 'singleSelect',
    valueOptions: getEnumValues(ProjectTaskStatus),
    field: 'status',
    flex: 1,
    minWidth: 60,
    maxWidth: 60,
    align: 'center',
    headerAlign: 'center',
    headerName: formatMessage({ id: 'Status' }),
    valueGetter: ({ row }) => row.status,
    renderCell: ({ row }) => (
      <TaskStatusDisplay status={row.status} iconOnly />
    )
  },
  {
    ...commonColumnOptions,
    type: 'actions',
    field: 'actions',
    headerName: '',
    minWidth: 40,
    maxWidth: 40,
    renderCell: ({ row }) => (
      <TaskActions task={row} />
    )
  }];


  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: formatMessage({ id: 'Name' }),
    flex: 1,
    minWidth: 500,
    renderCell: params => <TaskTreeDataGroupingCell depthOffsetAmount={2} {...params} />
  };

  return (
    <Stack height='100%' width='100%' gap={1}>
      <Stack direction='row' justifyContent='space-between'>
        <ToggleButtonGroupAlternate
          value={viewMode}
          onChange={(value) => setViewMode(value)}
          options={['Day', 'Week', 'Month']}
          messages={{
            Day: formatMessage({ id: 'Day' }),
            Week: formatMessage({ id: 'Week' }),
            Month: formatMessage({ id: 'Month' }),
            'Half Day': formatMessage({ id: 'Half Day' }),
            'Quarter Day': formatMessage({ id: 'Quarter Day' }),
            'Year': formatMessage({ id: 'Year' })
          }}
        />

        <Tooltip title={hideOldTasks ? formatMessage({ id: 'Completed tasks older than 1 month will be shown' }) : formatMessage({ id: 'Completed tasks older than 1 month will be hidden' })}>
          <Button startIcon={hideOldTasks ? <Visibility /> : <VisibilityOff />} onClick={() => setHideOldTasks(p => !p)}>
            {hideOldTasks ? formatMessage({ id: 'Show old tasks' }) : formatMessage({ id: 'Hide old tasks' })}
          </Button>
        </Tooltip>
      </Stack>

      <Stack height='100%' width='100%' position='relative'>
        <LinearProgress sx={{ py: 0, visibility: isRescheduling ? 'visible' : 'hidden' }} />
        <Stack
          ref={tasksScrollableRef}
          onScroll={onGanttScroll}
          position='absolute'
          direction='row'
          top={5}
          bottom={0}
          left={0}
          right={0}
          sx={{ backgroundColor: 'background.paper', overflow: 'auto' }}
        >
          <Box ref={tasksDataGridRef} minWidth='450px' position='sticky' left={0} zIndex={1000}>
            <DataGridPro<ProjectTaskFragmentFragment>
              apiRef={apiRef}
              treeData
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}
              disableColumnResize
              autoHeight
              columnHeaderHeight={59}
              rowHeight={38}
              hideFooter
              columns={columns}
              pinnedColumns={{ right: ['status', 'actions'] }}
              defaultGroupingExpansionDepth={5}
              rows={hierarchyFixedTasks ?? []}
              sx={{ backgroundColor: 'background.paper' }}
            />
          </Box>

          {ganttTasks.length > 0 && (
            <GanttWrapper
              defaultTasks={ganttTasks}
              readOnly={disabled}
              onTaskDateChange={onTaskDateChanged}
              onViewModeChanged={centerTasksScroll}
              onTaskDoubleClick={onTaskDoubleClick}
              viewMode={viewMode}
              options={options}
              scrollYOffset={ganttViewScrollYOffset}
              className={`gantt-${mode}`}
            />
          )}
        </Stack>
      </Stack>

      <TaskFormDrawer
        taskId={selectedTaskId}
        open={selectedTaskId != null}
        onClose={() => setSelectedTaskId(undefined)}
      />
    </Stack>
  );
};
