import { DataTable } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { AvatarGroup, Chip, ListItemText, Typography, Link as MuiLink } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { MeetingFragmentFragment } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { MeetingActions } from './MeetingActions';
import { MeetingStatusChip } from './MeetingStatusChip';
import { Link } from 'react-router-dom';


interface Props {
  meetings: MeetingFragmentFragment[];
  loading?: boolean;
}

export const MeetingsTable: React.FC<Props> = ({ meetings, loading }) => {
  const { formatMessage, formatDate } = useIntl();

  const columns: GridColDef<MeetingFragmentFragment>[] = [
    {
      field: 'name',
      flex: 2,
      minWidth: 150,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.name,
      renderCell: ({ row }) => (
        <MuiLink component={Link} to={row.id.toString()}>
          <ListItemText
            sx={{ p: 2 }}
            primary={row.name}
            secondary={row.description}
          />
        </MuiLink>
      )
    },
    {
      field: 'startTime',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Start time' }),
      valueGetter: ({ row }) => row.startTime,
      valueFormatter: ({ value }) => value ? formatDate(dayjs(value).toDate(), { timeStyle: 'short', dateStyle: 'short' }) : formatMessage({ id: 'To be determined' }),
      renderCell: ({ formattedValue, value }) => value ? formattedValue : (
        <Typography fontStyle='italic'>
          {formattedValue}
        </Typography>
      )
    },
    {
      field: 'duration',
      flex: 1,
      minWidth: 100,
      headerName: formatMessage({ id: 'Duration' }),
      valueGetter: ({ row }) => `${dayjs.duration(row.meetingType?.duration).asMinutes()} ${formatMessage({ id: 'minutes' })}`
    },
    {
      field: 'createdBy',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created by' }),
      valueGetter: ({ row }) => row.createdBy?.fullName,
      renderCell: ({ value }) => value && <Chip avatar={<UserAvatar displayName={value} />} label={value} />
    },
    {
      field: 'participants',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Participants' }),
      valueGetter: ({ row }) => row.participants,
      renderCell: ({ row }) => (
        <AvatarGroup>
          {row.participants.map(user => (
            <UserAvatar key={user.fullName} displayName={user.fullName} />
          ))}
        </AvatarGroup>
      )
    },
    {
      field: 'meetingStatus',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Status' }),
      valueGetter: ({ row }) => row.meetingStatus,
      renderCell: ({ value }) => (
        <MeetingStatusChip status={value} />
      )
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      maxWidth: 64,
      renderCell: ({ row }) => (
        <MeetingActions meeting={row} />
      )
    }
  ];

  return (
    <DataTable
      disableColumnMenu
      loading={loading}
      columns={columns}
      rows={meetings ?? []}
      noDataMessage={formatMessage({ id: 'No upcoming meeting' })}
    />
  );
};