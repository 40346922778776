import { Property } from '@components/DataDisplay/Property';
import { TextProperty } from '@components/DataDisplay/TextProperty';
import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionPart } from '@components/PageSection/PageSectionPart';
import { AddMeetingFormValues } from '@modules/meetings/types';
import { List, ListItem, ListItemText } from '@mui/material';
import dayjs from 'dayjs';
import { BookingMethod, MeetingTypesQuery } from 'gql/index';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';


type Props = {
  meetingType: MeetingTypesQuery['meetingTypes'][0] | undefined;
};

export const MeetingRequestSummaryStep = (props: Props) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<AddMeetingFormValues>();
  const proposedTimes = useWatch({ control: control, name: 'proposedTimes' });

  return <>
    <PageSection>
      <PageSectionPart gap={2}>
        <TextProperty propertyName={formatMessage({ id: 'Meeting type' })} propertyValue={props.meetingType?.name} />
        <TextProperty propertyName={formatMessage({ id: 'Duration' })} propertyValue={`${dayjs.duration(props.meetingType?.duration).asMinutes()} ${formatMessage({ id: 'minutes' })}`} />
        <Property propertyName={props.meetingType?.bookingMethod == BookingMethod.ProposeTimes
          ? formatMessage({ id: 'Proposed times' })
          : formatMessage({ id: 'Meeting time' })}>
          <List>
            {proposedTimes.map((p) => <ListItem key={p.start}>
              <ListItemText> {`${dayjs(p.start).format('LLL')} - ${dayjs(p.end).format('LT')} `} </ListItemText>
            </ListItem>)}
          </List>
        </Property>
      </PageSectionPart>
    </PageSection>

  </>;
};