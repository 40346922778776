import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add, Close } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MeetingType, useMeetingTypesQuery } from 'gql/index';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { MeetingTypeDrawer } from './MeetingTypeDrawer';

type Props = {
  error?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
  disabled?: boolean;
  value: number | undefined;
  onMeetingTypeChange: (meetingTypeId: number | null) => void;
};

export const MeetingTypeSelect: React.FC<Props> = ({ error, helperText, disabled, value, required, label, onMeetingTypeChange }) => {
  const [isSelectingDialogOpen, setIsSelectingDialogOpen] = useState(false);
  const [isAddMeetingTypeDrawerOpen, setIsAddMeetingTypeDrawerOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const { data: meetingTypes, refetch: refetchMeetingTypes } = useMeetingTypesQuery({ projectId }, { select: d => d.meetingTypes });

  const formatMeetingType = useCallback((meetingType: Pick<MeetingType, 'name' | 'duration'>) => `${meetingType.name} - ${dayjs.duration(meetingType?.duration).asMinutes()} ${formatMessage({ id: 'minutes' })}`, [formatMessage]);

  const handleMeetingTypeCreated = useCallback((newMeetingTypeId: number) => {
    refetchMeetingTypes();
    onMeetingTypeChange(newMeetingTypeId);
  }, [onMeetingTypeChange, refetchMeetingTypes]);

  return <>
    <FormControl focused={isSelectingDialogOpen} required={required}>
      <InputLabel error={!!error} disabled={disabled} shrink={isSelectingDialogOpen || !!value}>
        {label ?? formatMessage({ id: 'Meeting type' })}
      </InputLabel>
      <Select
        label={label ?? formatMessage({ id: 'Meeting type' })}
        onClick={() => !disabled && setIsSelectingDialogOpen(true)}
        value={value}

        disabled={disabled}
        error={!!error}
        onChange={(e) => onMeetingTypeChange(Number(e.target.value))}
        endAdornment={value != null && (
          <IconButton size='small' disabled={disabled} onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onMeetingTypeChange(null);
          }}>
            <Close />
          </IconButton>
        )}
        sx={{ minWidth: 450, '.MuiSelect-icon': { display: 'none' } }}
      >
        {(meetingTypes ?? []).map(p => (
          <MenuItem key={p.id} value={p.id.toString()}>
            {formatMeetingType(p)}
          </MenuItem>
        ))}

        {(meetingTypes ?? []).length == 0 && <MenuItem key='empty'>
          <Stack justifyContent={'center'} alignItems={'center'} gap={2}>
            <Typography> {formatMessage({ id: 'You need to start by adding meeting types to your project.' })}</Typography>
            <Button
              startIcon={<Add />}
              onClick={() => setIsAddMeetingTypeDrawerOpen(true)}
              sx={{ width: 300 }}>
              {formatMessage({ id: 'Add meeting type' })}
            </Button>
          </Stack>
        </MenuItem>
        }
      </Select>
      {(error || helperText) && <FormHelperText error={!!error}>{error ?? helperText}</FormHelperText>}
    </FormControl >
    <MeetingTypeDrawer
      sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
      open={isAddMeetingTypeDrawerOpen}
      onClose={() => setIsAddMeetingTypeDrawerOpen(false)}
      onMeetingTypeCreated={handleMeetingTypeCreated}
    />
  </>;
};