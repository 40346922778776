import { CalendarMonth, Draw, EditNote, Task } from '@mui/icons-material';
import { alpha, Theme } from '@mui/material';
import { WorkflowActionForExecutionFragmentFragment, WorkflowActionStatus } from 'gql/index';
import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { CompleteDocumentSignatureActionExecution } from '../../Actions/CompleteDocumentSignature.tsx/CompleteDocumentSignatureActionExecution';
import { ConfigureDocumentSignatureActionExecution } from '../../Actions/ConfigureDocumentSignature/ConfigureDocumentSignatureActionExecution';
import { FillFormActionExecution } from '../../Actions/FillForm/FillFormActionExecution';
import { RequestUserToBookAMeetingExecution } from '../../Actions/RequestUserToBookAMeeting/RequestUserToBookAMeetingExecution';
import { TaskActionExecution } from '../../Actions/Task/TaskActionExecution';
import { AllActionIds } from '../../WorkflowEditor/Actions/types';

export const ActionAccordionBgColor: Record<WorkflowActionStatus, (theme: Theme) => string> = {
  [WorkflowActionStatus.Completed]: (theme: Theme) => alpha(theme.palette.success.main, theme.palette.action.hoverOpacity),
  [WorkflowActionStatus.InProgress]: (theme: Theme) => theme.palette.background.paper,
  [WorkflowActionStatus.NotStarted]: (theme: Theme) => alpha(theme.palette.warning.main, theme.palette.action.hoverOpacity),
  [WorkflowActionStatus.Error]: (theme: Theme) => alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
  [WorkflowActionStatus.Stopped]: (theme: Theme) => alpha(theme.palette.warning.main, theme.palette.action.hoverOpacity)
};

export interface ActionAccordionProps {
  workflowId: number;
  action: WorkflowActionForExecutionFragmentFragment;
}

export const ActionExecutionAccordionByType: Record<AllActionIds, React.FC<ActionAccordionProps>> = {
  'Internal.FillForm': FillFormActionExecution,
  'Internal.Task': TaskActionExecution,
  'Internal.ConfigureDocumentSignature': ConfigureDocumentSignatureActionExecution,
  'Internal.CompleteDocumentSignature': CompleteDocumentSignatureActionExecution,
  'Internal.RequestUserToBookAMeeting': RequestUserToBookAMeetingExecution,
};

export const ActionIconByType: Record<AllActionIds, React.ReactNode> = {
  'Internal.FillForm': <EditNote />,
  'Internal.Task': <Task />,
  'Internal.ConfigureDocumentSignature': <Draw />,
  'Internal.CompleteDocumentSignature': <Draw />,
  'Internal.RequestUserToBookAMeeting': <CalendarMonth />
};

export const ActionNameByType: Record<AllActionIds, MessageDescriptor> = defineMessages({
  'Internal.FillForm': { id: 'Fill a form' },
  'Internal.Task': { id: 'Perform a task' },
  'Internal.ConfigureDocumentSignature': { id: 'Request a document signature' },
  'Internal.CompleteDocumentSignature': { id: 'Complete document signature' },
  'Internal.RequestUserToBookAMeeting': { id: 'Request a user to book a meeting' }
});