import { EmptyState } from '@components/EmptyState';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { AddMeetingFormValues } from '@modules/meetings/types';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Button, CircularProgress, FormHelperText, List, ListItem, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Availability, AvailabilityInfo, BookingMethod, useMeetingTypeOwnersAvailabilitiesQuery } from 'gql/index';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AvailabilityDay } from './AvailabilityDay';

type Props = {
  meetingDurationInMinutes: number,
  bookingMethod: BookingMethod
};

export const MeetingAvailabilityPickerStep = (props: Props) => {
  const { meetingDurationInMinutes, bookingMethod } = props;

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { formatMessage, formatDateTimeRange } = useIntl();

  const { control, register, formState: { errors } } = useFormContext<AddMeetingFormValues>();
  const meetingTypeId = useWatch({ control: control, name: 'meetingTypeId' });
  const { fields, append, remove } = useFieldArray({ control: control, name: 'proposedTimes', keyName: 'uniqueId' });
  const removeAll = () => remove();
  const { projectId } = useCurrentProject();
  const shouldDisableDate = (date: string | Dayjs) => fields.some(p => dayjs(p.start).isSame(dayjs(date), 'minute'));
  const shouldDisableAvailability = (availability: AvailabilityInfo) => availability.availability !== Availability.Free || dayjs(availability.startTime).isBefore(dayjs());

  register('proposedTimes', {
    required: bookingMethod == BookingMethod.ProposeTimes
      ? formatMessage({ id: 'Please select at least one time slot.' })
      : formatMessage({ id: 'Please select a time slot.' })
  });

  const error = errors.proposedTimes?.['root']?.message;

  const { data: outlookAvailabilities, isLoading } = useMeetingTypeOwnersAvailabilitiesQuery(
    {
      input: {
        startTime: selectedDate.startOf('day'),
        endTime: selectedDate.endOf('day'),
        projectId: projectId,
        meetingTypeId: meetingTypeId!,
        timeIntervalInMinutes: meetingDurationInMinutes,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    {
      enabled: !!meetingTypeId,
      select: p => p.calendarAvailabilitiesForMeetingType
    }
  );

  const isWorkingHourDay = useCallback((date: Dayjs) => {
    if (outlookAvailabilities?.isScheduleAvailable && outlookAvailabilities.isoWorkWeekDays.length > 0) {
      return outlookAvailabilities.isoWorkWeekDays.some(dayOfWeek => dayOfWeek == date.isoWeekday());
    }
    else {
      return date.isoWeekday() >= 1 && date.isoWeekday() <= 5;
    }
  }, [outlookAvailabilities]);

  const defaultAvailableTimes = useMemo(() => {
    const availabilities = [];
    let startTime = selectedDate.startOf('day').add(8, 'hour');
    if (selectedDate.isSame(dayjs(), 'date')) {
      startTime = selectedDate.startOf('hour').add(1, 'hour');
    }

    while (startTime.add(meetingDurationInMinutes, 'minutes').hour() < 17) {
      availabilities.push(startTime);
      startTime = startTime.add(15, 'minutes');
    }
    return availabilities;
  }, [meetingDurationInMinutes, selectedDate]);

  const isOutlookScheduleAvailable = outlookAvailabilities?.isScheduleAvailable == true;

  const availabilities = isOutlookScheduleAvailable
    ? outlookAvailabilities.availableStartTimes.filter(a => a.availability === Availability.Free).map(a => dayjs(a.startTime))
    : defaultAvailableTimes;

  const onTimeSelected = (time: Dayjs) => {

    const existingProposedTimeIndex = fields.findIndex(p => dayjs(p.start).isSame(time, 'minute'));
    if (existingProposedTimeIndex >= 0) {
      remove(existingProposedTimeIndex);
    }
    else if (bookingMethod == BookingMethod.DirectBooking) {
      remove(0);
      append({ start: time.toISOString(), end: time.add(meetingDurationInMinutes, 'minutes').toISOString() });
    }
    else if (fields.length < 3) {
      append({ start: time.toISOString(), end: time.add(meetingDurationInMinutes, 'minutes').toISOString() });
    }
  };

  return <>
    <Stack alignItems='center' justifyContent='center'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          disablePast
          shouldDisableDate={date => !isWorkingHourDay(dayjs(date))}

          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          slots={{
            day: AvailabilityDay
          }}
        />
      </LocalizationProvider>
    </Stack>

    {selectedDate && <>

      {bookingMethod == BookingMethod.ProposeTimes &&
        <Stack px={2} pb={1} direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='h6'>
            {formatMessage({ id: 'Available times. Selected {num} out of 3.' }, { num: fields.length })}
          </Typography>

          <Button color='inherit' onClick={removeAll}>
            {formatMessage({ id: 'Reset' })}
          </Button>
        </Stack>}

      <List sx={{ p: 2, mx: 2, border: t => error && `1px solid ${t.palette.error.main}` }}>
        {isLoading && <ListItem><CircularProgress /></ListItem>}

        {!isLoading && availabilities.length == 0 && <EmptyState hideImage title={formatMessage({ id: 'There are no time slots available for this date.' })} />}

        {!isLoading && !isOutlookScheduleAvailable && (
          <Grid2 container spacing={1} columns={4}>
            {defaultAvailableTimes.map((p, i) => (
              <Grid2 md={1} xs={4} key={i}>
                <Button fullWidth variant={shouldDisableDate(p) ? 'contained' : 'outlined'} onClick={() => onTimeSelected(p)}>
                  {formatDateTimeRange(p.toDate(), p.add(meetingDurationInMinutes, 'minutes').toDate(), { timeStyle: 'short' })}
                </Button>
              </Grid2>
            ))}
          </Grid2>
        )}

        {!isLoading && isOutlookScheduleAvailable && (
          <Grid2 container spacing={1} columns={4}>
            {outlookAvailabilities.availableStartTimes.map((p, i) => (
              <Grid2 md={1} xs={4} key={i}>
                <DisableableTooltip disabled={!shouldDisableAvailability(p)} title={formatMessage({ id: 'This time slot is not available, please pick another.' })}>
                  <Button fullWidth disabled={shouldDisableAvailability(p)} variant={shouldDisableDate(p.startTime) ? 'contained' : 'outlined'} onClick={() => onTimeSelected(dayjs(p.startTime))}>
                    {formatDateTimeRange(dayjs(p.startTime).toDate(), dayjs(p.startTime).add(meetingDurationInMinutes, 'minutes').toDate(), { timeStyle: 'short' })}
                  </Button>
                </DisableableTooltip>
              </Grid2>
            ))}
          </Grid2>
        )}
      </List>
    </>}

    {error && (
      <FormHelperText error sx={{ px: 2 }}>
        {error}
      </FormHelperText>
    )}
  </>;
};