import { ReactComponent as Subscribe } from '@assets/images/undraw_subscriptions.svg';
import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { AppSubscription, SubscriptionProvider } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
  subscription: Pick<AppSubscription, 'subscriptionProvider'>;
}

export const InactiveSubscriptionModal: React.FC<Props> = ({ subscription }) => {
  const { formatMessage } = useIntl();
  const onManageSubscriptionClicked = async () => {
    location.replace(`/${getTenantIdentifier()}/api/Subscription/Manage`);
  };

  const theme = useTheme();

  return (
    <ResponsiveDialog open sx={{ backdropFilter: 'blur(3px)' }} maxWidth='md'>
      <Stack alignItems='center' justifyContent='center' p={4} gap={2}>
        <Subscribe fill={theme.palette.primary.main} style={{ width: 'auto', height: 'auto', maxWidth: '300px' }} />

        <Typography mt={4} variant='h4' color='primary.main'>
          {formatMessage({ id: 'Your subscription is paused until a paid plan is selected.' })}
        </Typography>

        {subscription.subscriptionProvider === SubscriptionProvider.AzureMarketplace &&
        <>
          <Typography >
            {formatMessage({ id: 'Upgrade your Azure saas subscription to a paid plan to continue using the app.' })}
          </Typography>
          <Button
            onClick={() => window.open('https://portal.azure.com')}
            sx={{ mt: 2 }}
            variant='contained'>
            {formatMessage({ id: 'Manage in Azure' })}
          </Button>
        </>
        }

        {subscription.subscriptionProvider !== SubscriptionProvider.AzureMarketplace &&
          <>
            <Typography >
              {formatMessage({ id: 'Upgrade to a paid plan to continue using the app.' })}
            </Typography>

            <Button
              onClick={onManageSubscriptionClicked}
              sx={{ mt: 2 }}
              variant='contained'>
              {formatMessage({ id: 'Buy now' })}
            </Button>
          </>
        }
      </Stack>
    </ResponsiveDialog>
  );
};