import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Accordion, AccordionProps, styled } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { WorkflowActionForExecutionFragmentFragment, WorkflowActionStatus } from 'gql/index';
import { ReactNode, useState } from 'react';
import { ActionTimelineConnector } from '../ActionTimelineConnector';
import { ActionAccordionBgColor, } from '../Types';
import { ActionAccordionActions, WorkflowActionSubmitAction } from './ActionAccordionActions';
import { ActionAccordionDetails } from './ActionAccordionDetails';
import { ActionAccordionSummary } from './ActionAccordionSummary';


type OwnProps = Omit<AccordionProps, 'children'> & {
  action: WorkflowActionForExecutionFragmentFragment,
  subtitle?: string,
  submitAction?: WorkflowActionSubmitAction,
  children?: ReactNode;
};

type StyledAccordionProps = AccordionProps & {
  status: WorkflowActionStatus;
};

const StyledAccordion = styled(Accordion)<StyledAccordionProps>(({ theme, status }) => ({
  background: ActionAccordionBgColor[status](theme),
  width: '100%'
}));


export const ActionAccordion: React.FC<OwnProps> = ({ action, subtitle, submitAction, children }) => {
  const { me } = useGetMe();
  const { canManageWorkflows } = useCurrentProject();
  const canViewActionDetails = canManageWorkflows || action.participants.at(0)?.id == me?.id;
  const canExpandAction = canViewActionDetails || action.status == WorkflowActionStatus.Completed;
  const [expanded, setExpanded] = useState(canViewActionDetails && action.status === WorkflowActionStatus.InProgress);

  return (
    <ActionTimelineConnector isFirst={action.order === 0} id={action.id.toString()}>
      <StyledAccordion disableGutters status={action.status}
        expanded={canExpandAction ? expanded : false}
        onChange={(_, value) => setExpanded(value)}>
        <ActionAccordionSummary
          action={action}
          subTitle={subtitle}
        />
        <ActionAccordionDetails actionStatus={action.status} participant={action.participants.at(0)}>
          {children}
        </ActionAccordionDetails>

        <ActionAccordionActions
          action={action}
          submitAction={submitAction}

        />
      </StyledAccordion>
    </ActionTimelineConnector>
  );
};