import { Box, Button } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { SubscriptionProvider, useTenantSubscriptionDetailsQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { SectionTitle } from '../SectionTitle';

export const SubscriptionSection = () => {
  const { formatMessage } = useIntl();
  const { data: subscription } = useTenantSubscriptionDetailsQuery();

  if (!subscription?.tenantSubscriptionDetails.subscription == null) {
    return undefined;
  }

  const onManageSubscriptionClicked = async () => {
    location.replace(`/${getTenantIdentifier()}/api/Subscription/Manage`);
  };

  if (!subscription?.tenantSubscriptionDetails.hasSubscription) {
    return undefined;
  }

  return <Box padding={2}>
    <SectionTitle title={formatMessage({ id: 'Subscription' })} />

    {subscription.tenantSubscriptionDetails.subscription?.subscriptionProvider != SubscriptionProvider.AzureMarketplace &&
      <Button
        variant='text'
        onClick={onManageSubscriptionClicked}>
        {formatMessage({ id: 'Manage my subscription ' })}

      </Button>
    }

    {subscription.tenantSubscriptionDetails.subscription?.subscriptionProvider === SubscriptionProvider.AzureMarketplace &&

      <Button
        onClick={() => window.open('https://portal.azure.com')}
        sx={{ mt: 2 }}
        variant='text'>
        {formatMessage({ id: 'Manage in Azure' })}
      </Button>
    }
  </Box >;
};