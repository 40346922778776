import { RequestMeetingDialog } from '@modules/meetings/components/MeetingProposalCreator/RequestMeetingDialog';
import { AddMeetingFormValues } from '@modules/meetings/types';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { CalendarMonth } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import dayjs from 'dayjs';
import { Scalars, WorkflowActionStatus, useActionsOfWorkflowInstanceQuery, useMeetingTypesQuery, useSubmitWorkflowActionMutation, useWorkflowInstanceQuery, useWorkflowInstancesQuery } from 'gql/index';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionAccordion } from '../../WorflowInstance/Actions/ActionAccordion/ActionAccordion';
import { ActionAccordionProps } from '../../WorflowInstance/Actions/Types';
import { RequestUserToBookAMeetingDefinition } from './RequestUserToBookAMeetingDefinition';

type RequestUserToBookAMeetingInput = {
  proposedStartTimes: Array<Scalars['DateTime']['input']>,
  timeZone: string
};

export const RequestUserToBookAMeetingExecution: React.FC<ActionAccordionProps> = ({ workflowId, action }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const [isAddMeetingDialogOpen, setIsAddMeetingDialogOpen] = useState(false);
  const { mutate: submitWorkflowAction, isLoading: isSubmitting } = useSubmitWorkflowActionMutation({
    onSuccess: () => {
      invalidateQuery(useWorkflowInstanceQuery, { projectId, workflowId });
      invalidateQuery(useActionsOfWorkflowInstanceQuery, { workflowId });
      invalidateQuery(useWorkflowInstancesQuery, { projectId });
      setIsAddMeetingDialogOpen(false);
      notifySuccess(formatMessage({ id: 'Workflow action completed successfully.' }));
    }
  });

  const handleSubmit = (values: AddMeetingFormValues) => {
    const payload: RequestUserToBookAMeetingInput = {
      proposedStartTimes: values.proposedTimes.map(p => dayjs(p.start).toISOString()),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    submitWorkflowAction({
      input: {
        workflowActionId: action.id,
        workflowInstanceId: workflowId,
        jsonPayload: JSON.stringify(payload),
      }
    });
  };

  const meetingTypeId = useMemo(() => {
    return Number(action.fieldValues.find(f => f.fieldId === RequestUserToBookAMeetingDefinition.FieldId_MeetingType)?.value?.serializedValue);
  }, [action.fieldValues]);

  const { data: meetingType } = useMeetingTypesQuery(
    { projectId: projectId },
    { enabled: !!meetingTypeId, select: p => p.meetingTypes.find(p => p.id === meetingTypeId) });

  const handleOnClick = () => setIsAddMeetingDialogOpen(true);

  return (<>
    <ActionAccordion
      action={action}
      subtitle={meetingType?.name}
      submitAction={{
        buttonIcon: <CalendarMonth />,
        buttonText: formatMessage({ id: 'Schedule a meeting' }),
        onClick: handleOnClick,
        loading: isSubmitting
      }}
    >
      {action.status === WorkflowActionStatus.Completed &&
        <Typography>{formatMessage({ id: 'A meeting request has been sent.' })}</Typography>
      }
    </ActionAccordion>

    <RequestMeetingDialog
      open={isAddMeetingDialogOpen}
      onClose={() => setIsAddMeetingDialogOpen(false)}
      meetingTypeId={meetingTypeId}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit} />
  </>
  );
};