import { LoadingScreen } from '@components/LoadingScreen';
import { GoogleLoginButton, MicrosoftLoginButton } from '@components/LoginButtons';
import { TenantThemeContext } from '@modules/tenants/components/TenantThemeContextProvider';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useNotification } from '../../../utils/useNotification';
import { displayTenantSwitcherAfterLoginState } from '../atoms';

export const TenantLoginPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [loggingInProvider, setLoggingInProvider] = useState<string>();
  const setDisplayTenantSwitcherAfterLoginState = useSetRecoilState(displayTenantSwitcherAfterLoginState);
  const [params, setParams] = useSearchParams();
  const [returnUrl, setReturnUrl] = useState<string>(`/${getTenantIdentifier()}`);
  const tenantContext = useContext(TenantThemeContext);
  const navigate = useNavigate();

  const { notifyFailure } = useNotification();

  const { me, isFetching, isFetched } = useGetMe();

  useEffect(() => {
    const returnUrlParam = params.get('returnUrl');

    if (!tenantContext.isBrandingEnabled) {
      if (returnUrlParam) {
        navigate(`/?returnUrl=${params.get('returnUrl')}`, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    }
    else if (returnUrlParam) {
      setParams((prevParams) => {
        prevParams.delete('returnUrl');
        return prevParams;
      });

      setReturnUrl(returnUrlParam);
    }
  }, [isFetched, me, navigate, notifyFailure, params, returnUrl, setDisplayTenantSwitcherAfterLoginState, setParams, tenantContext.isBrandingEnabled]);

  useEffect(() => {
    const loginError = params.get('error');
    if (loginError) {
      notifyFailure(loginError);
      setParams((prevParams) => {
        prevParams.delete('error');
        return prevParams;
      });
    }
  }, [notifyFailure, params, setParams]);

  if (isFetching) {
    return <LoadingScreen />;
  }


  if (me != null) {
    return <Navigate to={`/${getTenantIdentifier()}`} />;
  }

  const logo = tenantContext.squareLogoUrl ?? tenantContext.topBarLogoUrl;

  return <Stack
    sx={{
      backgroundImage: `url(${tenantContext.loginBackgroundUrl})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      flexGrow: 1
    }}
    flex={1}
    justifyContent={'center'}
    alignItems={'center'}>

    <Paper elevation={4} sx={{
      height: { xs: '100%', sm: 'auto' },
      width: { xs: '100%', sm: 'auto' }
    }}>
      <Stack p={8} gap={8} justifyContent={'center'} alignItems={'center'}>
        {logo && (
          <Box><img src={logo} height={200} /></Box>
        )}

        <Stack spacing={1} alignItems='center' justifyContent={'center'}>
          <Typography variant='h3' color='primary.main'>
            {formatMessage({ id: 'Welcome' })}
          </Typography>

          <Typography textAlign='center'>
            {formatMessage({ id: 'Please identify yourself to access the application' })}
          </Typography>
        </Stack>

        <Stack flex={1} spacing={2} alignItems='center'>
          <form id="external-account" action={'/api/Account/ExternalLogin'} method="post">
            {returnUrl && <input name='returnUrl' type='hidden' value={returnUrl} />}
            <MicrosoftLoginButton
              loading={loggingInProvider === 'OpenIdConnect'}
              disabled={Boolean(loggingInProvider) && loggingInProvider !== 'OpenIdConnect'}
              fullWidth
              type='submit' name='provider' value='OpenIdConnect'
              onClickCapture={() => setLoggingInProvider('OpenIdConnect')}
            />
          </form>
          <form id="external-account" action={'/api/Account/ExternalLogin'} method="post">
            {returnUrl && <input name='returnUrl' type='hidden' value={returnUrl} />}
            <GoogleLoginButton
              fullWidth
              loading={loggingInProvider === 'Google'}
              disabled={Boolean(loggingInProvider) && loggingInProvider !== 'Google'}
              type='submit' name='provider' value='Google'
              onClickCapture={() => setLoggingInProvider('Google')}
            />
          </form>
        </Stack>
      </Stack>

    </Paper>
  </Stack>;
};