import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { AvatarGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { getAvatarColor } from '@utils/userUtils';
import dayjs from 'dayjs';
import { MeetingTypesQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  meetingType: MeetingTypesQuery['meetingTypes'][number];
  onClick?: () => void;
  cardActions?: React.ReactNode;
}

export const MeetingTypeCard: React.FC<Props> = ({ meetingType, cardActions, onClick }) => {
  const { formatMessage } = useIntl();
  const inner = <>
    <CardHeader
      title={meetingType.name}
      subheader={meetingType.description}
    />

    <CardContent>
      <Stack direction={'row'} spacing={4} alignItems={'center'}>
        {meetingType.meetingRequestsOwners.length > 0 &&
          <AvatarGroup>
            {meetingType.meetingRequestsOwners.map(user => (<UserAvatar key={user.id} displayName={user.fullName} />))}

          </AvatarGroup>
        }

        <Typography>
          {`${dayjs.duration(meetingType?.duration).asMinutes()} ${formatMessage({ id: 'minutes' })}`}
        </Typography>
      </Stack>
    </CardContent>

    {cardActions && (
      <CardActions>
        {cardActions}
      </CardActions>
    )
    }
  </>;

  return (
    <Card
      variant='elevation'
      sx={{
        height: '100%',
        borderTop: '20px solid',
        borderColor: getAvatarColor(meetingType.name + meetingType.duration)
      }}
    >
      {onClick ? (
        <CardActionArea onClick={onClick}>
          {inner}
        </CardActionArea>
      ) : inner}
    </Card>
  );
};