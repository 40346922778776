import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { viewMode } from 'frappe-gantt';
import { atom } from 'recoil';
import { initializeFromLocalStorage } from '../application/atoms';
import { TasksView } from './types';

const tasksViewStorageKey = 'tasksView';
const tasksGanttViewModeKey = 'tasksGanttViewMode';
const tasksTableSortModelKey = 'tasksTableSortModel';
const tasksTableFilterModelKey = 'tasksTableFilterModel';

export const tasksViewState = atom<TasksView>({
  key: tasksViewStorageKey,
  default: 'list',
  effects: [
    initializeFromLocalStorage(tasksViewStorageKey)
  ]
});


export const tasksGanttViewModeState = atom<viewMode>({
  key: tasksGanttViewModeKey,
  default: 'Day',
  effects: [
    initializeFromLocalStorage(tasksGanttViewModeKey),
  ]
});

export const tasksTableSortModelState = atom<GridSortModel | undefined>({
  key: tasksTableSortModelKey,
  default: undefined,
  effects: [
    initializeFromLocalStorage(tasksTableSortModelKey),
  ]
});

export const tasksTableFilterModelState = atom<GridFilterModel | undefined>({
  key: tasksTableFilterModelKey,
  default: undefined,
  effects: [
    initializeFromLocalStorage(tasksTableFilterModelKey),
  ]
});
