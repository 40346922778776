import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add, ArrowForward } from '@mui/icons-material';
import { Alert, Stack, Tab, Tabs } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { MeetingStatus, useMeetingsQuery, useMeetingTypesQuery } from 'gql/index';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { RequestMeetingDialog } from '../components/MeetingProposalCreator/RequestMeetingDialog';
import { MeetingsTable } from '../components/MeetingsTable';
import { OutlookCalendarConsentAlert } from '../components/OutlookCalendarConsentAlert';
import dayjs from 'dayjs';

type MeetingRequestsViews = 'pendingRequests' | 'past';

export const MeetingsPage = () => {
  const { formatMessage } = useIntl();
  const { projectId, canManageProject } = useCurrentProject();
  const { isExternalUser } = useGetMe();
  const navigate = useNavigate();

  const { data: meetings, isFetching } = useMeetingsQuery({ projectId }, { select: d => d.meetings });

  const { data: meetingTypes } = useMeetingTypesQuery({ projectId }, { select: p => p.meetingTypes });

  const [activeView, setActiveView] = useState<MeetingRequestsViews>('pendingRequests');

  const meetingTypesExist = meetingTypes == null ? null : meetingTypes.length > 0;
  const filteredMeetings = useMemo(() => {
    let filteredMeetings = meetings;
    switch (activeView) {
      case 'pendingRequests':
        filteredMeetings = meetings?.filter(p => (p.startTime == null || dayjs(p.startTime).isAfter(dayjs().startOf('day')))
          && (p.meetingStatus == MeetingStatus.Requested || p.meetingStatus == MeetingStatus.Planned)) ?? [];
        break;
      case 'past':
        filteredMeetings = meetings?.filter(p => p.startTime != null && dayjs(p.startTime).isBefore(dayjs())) ?? [];
        break;
      default: throw new Error('Unsupported meetings view');
    }

    return filteredMeetings;
  }, [activeView, meetings]);

  const [isRequestingMeeting, setIsRequestingMeeting] = useState(false);

  return <>
    <PageContainer
      title={formatMessage({ id: 'Meetings' })}
      action={(
        <DisableableTooltip disabled={meetingTypesExist == true} title={formatMessage({ id: 'Meeting types have not been added to this project.' })}>
          <ResponsiveButton variant='contained'
            onClick={() => setIsRequestingMeeting(true)}
            disabled={!meetingTypesExist}

            icon={<Add />}>
            {formatMessage({ id: 'Request a meeting' })}
          </ResponsiveButton>
        </DisableableTooltip>
      )}
    >
      <Stack gap={2}>
        {meetingTypesExist == false && canManageProject &&
          <Alert severity='info' action={(
            <ResponsiveButton iconPosition='end' variant='outlined' icon={<ArrowForward />} onClick={() => navigate('../types')}>{formatMessage({ id: 'Meeting types' })}</ResponsiveButton>
          )} sx={{ flexWrap: 'wrap' }}>
            {formatMessage({ id: 'To let team members book meetings easily, start by configuring meeting types.' })}
          </Alert>
        }

        {meetingTypesExist && !isExternalUser &&
          <OutlookCalendarConsentAlert />
        }

        <Tabs value={activeView} onChange={(_, newValue) => setActiveView(newValue)}>
          <Tab value='pendingRequests' label={formatMessage({ id: 'Upcoming' })} />
          <Tab value='past' label={formatMessage({ id: 'History' })} />
        </Tabs>

        <MeetingsTable
          meetings={filteredMeetings}
          loading={isFetching}
        />

      </Stack>
    </PageContainer >

    <RequestMeetingDialog
      open={isRequestingMeeting}
      onClose={() => setIsRequestingMeeting(false)}
    />
  </>;
};