import { ActionMenu, ActionMenuProps } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { MeetingFragmentFragment, MeetingStatus, useDeleteMeetingMutation, useMeetingsQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

interface Props extends ActionMenuProps {
  meeting: MeetingFragmentFragment;
}

export const MeetingActions: React.FC<Props> = ({ meeting, ...menuProps }) => {
  const { projectId } = useCurrentProject();
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { me } = useGetMe();
  const [isDeleting, setIsDeleting] = useState(false);

  const { mutate: deleteMeeting, isLoading } = useDeleteMeetingMutation({
    onSuccess: () => {
      invalidateQuery(useMeetingsQuery, { projectId });
      notifySuccess(formatMessage({ id: 'Deleted meeting successfully' }));
    }
  });

  const meetingTypeOwners = meeting.meetingType?.meetingRequestOwnerGroups
    .flatMap(p => p.members.map(q => q.id))
    .concat(meeting.meetingType?.meetingRequestsOwners.map(p => p.id)) ?? [];
  const isMeetingTypeOwner = meetingTypeOwners.some(p => p === me?.id);


  const canDeleteMeeting = isMeetingTypeOwner || (meeting.meetingStatus != MeetingStatus.Cancelled && meeting.createdById == me?.id);

  if (!canDeleteMeeting) {
    return undefined;
  }

  return <>
    <ActionMenu {...menuProps}>
      <MenuItem onClick={() => setIsDeleting(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        {formatMessage({ id: 'Delete' })}
      </MenuItem>
    </ActionMenu>

    <ConfirmDialog
      open={isDeleting}
      onCancel={() => setIsDeleting(false)}
      title={formatMessage({ id: 'Delete meeting?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      content={formatMessage({ id: 'This action cannot be undone.' })}
      loading={isLoading}
      onConfirm={() => deleteMeeting({ input: { projectId, id: meeting.id } })}
    />
  </>;
};