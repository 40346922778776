import { PageContainer } from '@components/Layout/PageContainer';
import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionPart } from '@components/PageSection/PageSectionPart';
import { PageSectionSkeleton } from '@components/Skeletons/PageSectionSkeleton';
import { UnstyledLink } from '@components/UnstyledLink';
import { CheckCircle, Checklist, ChevronRight, EditCalendar, Folder, Group, RemoveRedEye, Savings, TaskAlt, Timeline } from '@mui/icons-material';
import { Alert, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Switch, Typography } from '@mui/material';
import { useEditProjectMutation, useGetProjectTemplateSummaryInfoQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { ProjectActions } from '../components/ProjectActions';
import { useCurrentProject } from '../utils/useCurrentProject';

const ChecklistItem = ({ primary, secondary, icon, isConfigured, link }: {
  primary: string;
  secondary: string;
  icon: React.ReactElement;
  isConfigured?: boolean | null;
  link: string;
}) => {
  return (
    <UnstyledLink to={link}>
      <ListItem disablePadding>
        <ListItemButton sx={{ px: { xs: 2, md: 4 } }}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={primary}
            secondary={secondary}
          />
          {isConfigured ? (
            <CheckCircle sx={{ ...isConfigured && { color: 'success.main' } }} />
          ) : (
            <ChevronRight />
          )}
        </ListItemButton>
      </ListItem>
    </UnstyledLink>
  );
};

export const ProjectTemplateHomePage: React.FC = () => {
  const { projectId, name, query, hideBudgetFeature, canManageProject } = useCurrentProject();
  const { data: templateInfo } = useGetProjectTemplateSummaryInfoQuery({ projectTemplateId: projectId }, { select: p => p.projectTemplateSummaryInfo });
  const { mutate: editProject, isLoading: isEditingProject } = useEditProjectMutation();
  const { formatMessage } = useIntl();

  const onBudgetEnabled = (enableBudgetFeature: boolean) => {
    editProject({
      input: {
        projectId,
        name: name ?? '',
        isArchived: false,
        hideBudgetFeature: !enableBudgetFeature
      }
    }, {
      onSuccess: () => query.refetch()
    });
  };

  const hasBudgetItems = templateInfo && templateInfo.budgetItemsCount > 0;
  const hasTasks = templateInfo && templateInfo.tasksCount > 0;
  const hasCustomFolders = templateInfo && templateInfo.customFoldersCount > 0;
  const hasDocuments = templateInfo && templateInfo.documentsCount > 0;
  const hasMeetingTypes = templateInfo && templateInfo.meetingTypesCount > 0;
  const hasForms = templateInfo && templateInfo.formsCount > 0;
  const hasExtraGroups = templateInfo && templateInfo.securityGroupsCount > 2;
  const hasWorkflows = templateInfo && templateInfo.workflowsCount > 0;

  return (
    <PageContainer title={formatMessage({ id: 'Configure your project template' })} action={<ProjectActions />}>
      {!canManageProject && <Alert severity='info'>{formatMessage({ id: 'You have not been granted permission to modify this project template.' })}</Alert>}

      {query.isFetching && <>
        <PageSectionSkeleton icon={undefined} title={''} />
      </>}
      {!query.isFetching && <Stack direction={'row'} gap={3} pt={1} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Stack flexGrow={1} gap={2}>
          <PageSectionPart sx={{ p: 0 }}>
            {!hideBudgetFeature && (
              <ChecklistItem
                primary={formatMessage({ id: 'Budget items' })}
                secondary={formatMessage({ id: 'Track remaining budget and optionally share it with your clients.' })}
                icon={<Savings />}
                isConfigured={hasBudgetItems}
                link={'../budget'}
              />
            )}

            <ChecklistItem
              primary={formatMessage({ id: 'Tasks' })}
              secondary={formatMessage({ id: 'Assign tasks to groups and organize parent tasks and predecessors.' })}
              icon={<TaskAlt />}
              isConfigured={hasTasks}
              link={'../tasks'}
            />

            <ChecklistItem
              primary={formatMessage({ id: 'Documents' })}
              secondary={formatMessage({ id: 'Create folders and upload files, such as guides and welcome documents.' })}
              icon={<Folder />}
              isConfigured={hasCustomFolders || hasDocuments}
              link={'../documents'}
            />

            <ChecklistItem
              primary={formatMessage({ id: 'Meeting types' })}
              secondary={formatMessage({ id: 'Allow users to book meetings with specific groups in your project.' })}
              icon={<EditCalendar />}
              isConfigured={hasMeetingTypes}
              link={'../meetings/types'}
            />

            <ChecklistItem
              primary={formatMessage({ id: 'Forms' })}
              secondary={formatMessage({ id: 'Request information from project members or let them initiate requests with self-serve forms.' })}
              icon={<Checklist />}
              isConfigured={hasForms}
              link={'../forms'}
            />


            <ChecklistItem
              primary={formatMessage({ id: 'Workflows' })}
              secondary={formatMessage({ id: 'Automate and simplify client collaboration with tailored workflows.' })}
              icon={<Timeline />}
              isConfigured={hasWorkflows}
              link={'../workflows/templates'}

            />
            <ChecklistItem
              primary={formatMessage({ id: 'Groups' })}
              secondary={formatMessage({ id: 'Add groups to manage permissions of complex projects.' })}
              icon={<Group />}
              isConfigured={hasExtraGroups}
              link={'../permissions/groups'}
            />

            <ChecklistItem
              primary={formatMessage({ id: 'Permissions' })}
              secondary={formatMessage({ id: 'Fine-tune permissions for complex projects involving multiple types of users.' })}
              icon={<RemoveRedEye />}
              isConfigured={false}
              link={'../permissions/permissions'}
            />
          </PageSectionPart>
        </Stack>

        <Stack flexGrow={1}>
          {canManageProject && (
            <PageSection title={formatMessage({ id: 'Optional features' })}>
              <PageSectionPart>
                <Stack direction={{ xs: 'column', md: 'row' }} alignItems='center' justifyContent='space-between' gap={2}>
                  <Stack>
                    <Typography variant='body1'>{formatMessage({ id: 'Budget' })}</Typography>
                    <Typography variant='body2'>{formatMessage({ id: 'Add a module to track budget and expenses.' })}</Typography>
                  </Stack>
                  <Switch
                    disabled={isEditingProject || !canManageProject}
                    checked={!hideBudgetFeature}
                    onChange={(_, checked) => onBudgetEnabled(checked)}
                  />
                </Stack>
              </PageSectionPart>
            </PageSection>
          )}
        </Stack>
      </Stack>
      }
    </PageContainer >
  );
};
